const config = {
  APP_ENV: "live", //local //live
  apiUrl_local: "http://localhost/ONEID/ONEID/Api/User",
  baseURL_local: "http://localhost/ONEID/ONEID/",
  apiUrl: "https://dev.1id.one/ONEID/Api/User",
  baseURL: "https://dev.1id.one/ONEID/",
  NodeAPIURL_local: "http://localhost:5222",
  NodeAPIURL: "https://dev.1id.one:5222",
  Website: "https://dev.1id.one",
  Website_local: "http://localhost:3000",
  APPSTORE: "https://apps.apple.com/us/app/1id-my-digital-profile/id1574314628",
  PLAYSTORE: "https://play.google.com/store/apps/details?id=com.oneidone&hl=en_IN&gl=US"
};

export default config;
