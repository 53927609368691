import React, { useEffect, useState, useContext } from "react";
import { Navigation } from "../component";
import { Footer } from "../component";
import identification from "../images/identification.png";
import ic_partner_account from "../images/ic_partner_account.png";
import regulations from "../images/regulations.png";
import security from "../images/security.png";
import final_mobile from "../images/final_mobile.png";
import global from "../images/global.png";
import user from "../images/user.png";
import data from "../images/data.png";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toast } from "react-toastify";
import logo from "../images/1_ID.png";
import qrmobile from "../images/code-mobile.png";
import success from "../images/success.png";
import warning from "../images/warning.png";
import backArrow from "../images/back-arrow.png";
import QRCode from "react-qr-code";
import { SocketContext } from "../component/socket";
import config from "../component/config";
import ReactFlagsSelect from "react-flags-select";
import { checkCompany, createSubscription, updateAccountInfo, getQuote } from "../services/services";

const Business = () => {
  const [modal, setModal] = useState(false);
  const [identifyModal, setIdentifyModal] = useState(false);
  const [accountModal, setAccountModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [plantype, setPlantype] = useState();

  const identifyClose = () => {
    setIdentifyModal(!identifyModal);
  };
  const account = (plan, planCode) => {
    console.log(plan, "planCode", planCode);
    setAccountModal(!accountModal);
    if (plan) {
      setPlantype(plan);
    }
    localStorage.setItem('plan_code', planCode);
  };
  const accountClose = () => {
    setAccountModal(!accountModal);
    setQrtrue(false);
    setReadInput(false);
    setFormValues({
      ...formValues,
      ["company_name"]: "",
      ["VAT_No"]: "",
      ["country"]: "",
      ["bill_street"]: "",
      ["bill_city"]: "",
      ["bill_state"]: "",
      ["bill_zip"]: "",
    });
  };

  const socket = useContext(SocketContext);
  const [respmsg, setRespmsg] = useState();
  const [readInput, setReadInput] = useState(false);
  const [loginType, setLoginType] = useState();
  const [isLoading, setLoading] = useState(false);
  const [device_id, setDeviceId] = useState(localStorage.getItem("deviceID"));

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem('plan_code', 'starter_free');
    socket.on("connect", function () {
      setDeviceId(socket.id);
      console.log("socketnavbar-->>>>", socket.id);
    });

    socket.on("message", async (data) => {
      console.log("react demo--->>>", data);
      if (data && data.ResponseCode && data.ResponseCode == 1) {
        // console.log(data.data.msg);
        // console.log('uidval',localStorage.getItem('deviceID'),'deviceid',data.data.device_id);
        if (data.data.device_id == localStorage.getItem("deviceID")) {
          if (data.data.type == "KYCSingUp") {
            setLoading(true);
            setAccountModal(false);
            setRespmsg(data.data.msg);
            messagesuc();
            setLoginType(data.data.type);
          } else if (data.data.type == "KYCSingUpSuccess") {
            setLoading(false);
            setAccountModal(false);
            setRespmsg(data.data.msg);
            // messagesuc();
            setLoginType(data.data.type);
            setSignCompany(data.data.company_name);


            let formData = new FormData()
            formData.append("companyName", data.data.company_name);
            formData.append("firstName", data.data.first_anme);
            formData.append("lastName", data.data.last_name);
            formData.append("phone", data.data.phone);
            formData.append("email", data.data.email);
            formData.append("partnerCode", data.data.partner_code);
            formData.append("company_id", data.data.company_id);
            let pricingCode = localStorage.getItem('plan_code');
            console.log('pricingCode-->>>', pricingCode);
            formData.append("priceListCode", pricingCode); //starter_free //basic_monthly //business_monthly
            if (pricingCode == "starter_free") {
              // trial not allow for free forever plan
              formData.append("is_trial", false);
            }
            else {
              formData.append("is_trial", true);
            }

            setLoading(true);
            let apiResponse = await createSubscription(formData);
            if (apiResponse.ResponseCode === 1 || apiResponse.ResponseCode === "1") {
              console.log('apiResponse-->>>', apiResponse);
              setLoading(false);


            } else {
              setLoading(false);
              // toast.error(apiResponse.ResponseMsg);
            }

            let formData1 = new FormData();
            formData1.append("billingStreet", data.data.billingStreet);
            formData1.append("billingCity", data.data.billingCity);
            formData1.append("billingState", data.data.billingState);
            formData1.append("billingZip", data.data.billingZip);
            formData1.append("billingCountry", data.data.country);
            formData1.append("taxNumber", data.data.vat_no);
            await updateAccountInfo(formData1, data.data.partner_code);

            const BASE_URL = (config.APP_ENV == "live") ? config.baseURL : config.baseURL_local;
            let redirectURL = BASE_URL + `Partner/Login/KycLogin?companyName=${data.data.company_name}&cId=${data.data.company_id}`;
            window.location = redirectURL;

          }
        }
      } else if (data && data.ResponseCode && data.ResponseCode == 2) {
        setLoading(false);
        setRespmsg(data.data.msg);
      }
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnecting");
    });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {

    var formDiv = document.querySelector(".form-wrapper");
    var loaderBox = document.querySelector(".loader-box");
    formDiv.classList.add("hide");
    loaderBox.classList.remove("hide");

    //e.preventDefault();
    var formData = new FormData();
    Object.keys(data).forEach((key) => {
      console.log(key, data[key]);
      formData.append(key, data[key]);
    });

    setLoading(true);

    let apiResponse = await getQuote(formData);
    if (apiResponse.ResponseCode === 1 || apiResponse.ResponseCode === "1") {
      setLoading(false);
      toggle();
      toast.success(apiResponse.ResponseMsg);
      loaderBox.classList.add("hide");
      formDiv.classList.remove("hide");

    } else {
      setLoading(false);
      toast.error(apiResponse.ResponseMsg);
    }


  };
  //console.log(errors);
  const intialValues = { company_name: "", VAT_No: "", bill_street: "", bill_city: "", bill_state: "", bill_zip: "" };
  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [qrtrue, setQrtrue] = useState(false);
  const [cname, setCname] = useState();
  const [vatno, setVatno] = useState();
  const [messageSuccess, setMessageSuccess] = useState(false);
  const [messageWarning, setMessageWarning] = useState(false);
  const [isCompany, setIsCompany] = useState(false);
  const [signCompany, setSignCompany] = useState();
  const [selected, setSelected] = useState("");
  const [sharePresent, setsharePresent] = useState(false);
  const [termsTrue, setTermsTrue] = useState(false);
  const [privacyTrue, setPrivacyTrue] = useState(false);
  const [privacyTerms, setPrivacyTerms] = useState(false);

  const messagesuc = () => setMessageSuccess(!messageSuccess);

  const messagesucClose = () => {
    setMessageSuccess(!messageSuccess);
    console.log(loginType);
    if (loginType == "KYCSingUp") {
      messagesuc(false);
      setIsCompany(false);
      setQrtrue(true);
      setReadInput(true);
    } else if (loginType == "KYCSingUpSuccess") {
      messagesuc(false);
      setIsCompany(false);
      setQrtrue(true);
      setReadInput(true);
    }
  };

  const messagewarn = () => setMessageWarning(!messageWarning);

  const submit = async () => {
    // console.log(formValues);

    // setIsCompany(false);
    // setQrtrue(true);
    // setReadInput(true);

    var formData = new FormData();
    formData.append("company_name", cname);
    formData.append("VAT_No", vatno);
    formData.append("country", selected);
    // console.log(selected);
    setLoading(true);

    let apiResponse = await checkCompany(formData);
    if (apiResponse.ResponseCode === 1 || apiResponse.ResponseCode === "1") {
      setLoading(false);
      setLoading(false);
      if (apiResponse.is_result == false || apiResponse.is_result == "false") {
        setIsCompany(true);
        setFormErrors(validate(formValues));
      } else {
        setIsCompany(false);
        setQrtrue(true);
        setReadInput(true);
      }


    } else {
      toast.error(apiResponse.ResponseMsg);
      setLoading(false);
      setIsCompany(false);
    }


  };

  //input change handler
  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
    setIsCompany(false);
    setFormErrors({
      ...formErrors,
      ["company_name"]: "",
      ["VAT_No"]: "",
      ["country"]: "",
      ["company_email"]: "",
      ["company_phone"]: "",
      ["bill_street"]: "",
      ["bill_city"]: "",
      ["bill_state"]: "",
      ["bill_zip"]: "",
    });
  };

  //form submission handler
  const generateQR = (e) => {
    e.preventDefault();

    setFormErrors(validate(formValues));
    setCname(formValues.company_name);
    setVatno(formValues.VAT_No);
    setIsSubmitting(true);
  };

  //form validation handler
  const validate = (values) => {
    let errors = {};

    if (!values.company_name) {
      errors.company_name = "Please enter company name.";
    }
    if (!values.VAT_No) {
      errors.VAT_No = "Please enter number.";
    } else {
      if (isCompany == true || isCompany == "true") {
        errors.VAT_No = "VAT number is already registered with us.";
      }
    }

    if (!selected) {
      errors.country = "Please select country.";
    }
    if (!values.company_email) {
      errors.company_email = "Please enter company email.";
    }
    if (!values.company_phone) {
      errors.company_phone = "Please enter company phone.";
    }
    if (!values.bill_street) {
      errors.bill_street = "Please enter street.";
    }
    if (!values.bill_city) {
      errors.bill_city = "Please enter city.";
    }
    if (!values.bill_state) {
      errors.bill_state = "Please enter state.";
    }
    if (!values.bill_zip) {
      errors.bill_zip = "Please enter zipcode.";
    }

    return errors;
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmitting) {
      submit();
    }
  }, [formErrors]);

  const termsCheck = () => {
    setTermsTrue(!termsTrue);
  };

  const dataShareCheck = () => {
    setsharePresent(!sharePresent);
  };
  const privacyCheck = () => {
    setPrivacyTrue(!privacyTrue);
  };

  const countryClose = (code) => {
    setSelected(code);
    var cbtn = document.getElementById("rfs-btn");
    if (code == "") {
      errors.country = "Please select country.";
    } else {
      setFormErrors({
        ...formErrors,
        ["company_name"]: "",
        ["VAT_No"]: "",
        ["country"]: "",
        ["bill_street"]: "",
        ["bill_city"]: "",
        ["bill_state"]: "",
        ["bill_zip"]: "",
      });
      errors.country = "";
    }
    cbtn.click();
  };

  useEffect(() => {
    console.log(privacyTrue);
    if (privacyTrue == true && termsTrue == true && sharePresent == true) {
      setPrivacyTerms(true);
    } else {
      setPrivacyTerms(false);
    }
  }, [privacyTrue]);

  useEffect(() => {
    console.log(termsTrue);
    if (privacyTrue == true && termsTrue == true && sharePresent == true) {
      setPrivacyTerms(true);
    } else {
      setPrivacyTerms(false);
    }
  }, [termsTrue]);

  useEffect(() => {
    console.log(sharePresent);
    if (privacyTrue == true && termsTrue == true && sharePresent == true) {
      setPrivacyTerms(true);
    } else {
      setPrivacyTerms(false);
    }
  }, [sharePresent]);

  return (
    <div>
      {isLoading && (
        <div className="loader-bg">
          <h3>Loading...</h3>
          <div className="loader">
            <span></span>
          </div>
        </div>
      )}
      <header>
        <SocketContext.Provider value={socket}>
          <Navigation />
        </SocketContext.Provider>
      </header>

      <section className="solution-banner busi-wrapper">
        <div className="container">
          <div className="section-title">
            <span>Solutions</span>
          </div>
          <h1>
            Solutions catered to your <span>Business.</span>
          </h1>


          <div className="bus-banner-content">
            <div className="price-wrapper">
              <div className="row">
                <div className="price-row">
                  <div className="col-12 price-title">
                    <div className="bus-image">
                      <img
                        src={identification}
                        alt="Troublesome Identification"
                      />
                    </div>
                    <ul><li className="f30"><h4>Auto Identification - Remote & On Location</h4></li> <li className="f30"><h4>Customer Onboarding & Authentication </h4></li> <li className="f30"> <h4>eSignature & eID</h4></li></ul>
                    {/* <p className="fw-bold">FREE Trial Until June 30, 2023.</p> */}
                    <p>
                      Implement automatic identification of all your customers or employees. <br /> Scan ID on location of event visitors when entering large venues. <br /> Identify customers during onboarding or login. <br />Integrate your eSignature solution with auto eID.
                    </p>
                  </div>
                  <div className="price-box-row">
                    <div className="price-box-column">
                      <div className="price-box">
                        <div className="price-header">
                          <h5>STARTER</h5>
                          <h3>FREE</h3>
                          <p>&nbsp; </p>
                        </div>

                        <div className="price-body">
                          <div className="features">
                            <p>&nbsp;</p>
                            <p className="feature-label text-center"></p>
                          </div>

                          <div className="features">
                            <ul>
                              <li className="font_bold">REMOTE ID - Manual</li>
                              <li>1-2 Operators</li>
                              <li>1-500 IDs</li>
                              <li>User ID data: Name; photo; mobile; email; 1ID no.</li>
                            </ul>
                          </div>
                        </div>

                        <div className="box-price-footer">
                          <button
                            className="price-btn"
                            onClick={() => account("starter", "starter_free")}
                          >
                            Free Sign Up
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="price-box-column">
                      <div className="price-box">
                        <div className="price-header">
                          <h5>BASIC</h5>
                          <h3>
                            €49.00 monthly <br />
                            {/* </span> */}
                          </h3>
                          <p>Plus €0,01/month/ID</p>
                          {/* <p>Free until October 1, 2022</p> */}
                        </div>

                        <div className="price-body">
                          <div className="features">
                            <p><b>Same as STARTER</b></p>
                            <p className="feature-label">
                            </p>
                          </div>
                          <div className="features">
                            <ul>
                              <li className="font_bold">REMOTE ID - Manual & Automated</li>
                              <li className="font_bold">AUTHENTICATION</li>
                              <li className="font_bold">ONBOARDING</li>
                              <li>1-10 Operators</li>
                              <li>1-2,000 IDs</li>
                              <li>1-2.000 Check ID upload / download</li>
                              <li>Email support</li>
                            </ul>
                          </div>
                        </div>

                        <div className="box-price-footer">
                          <button
                            className="price-btn"
                            onClick={() => account("basic", "basic_monthly")}
                          >
                            14 DAYS FREE TRIAL
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="price-box-column">
                      <div className="price-box active-plan">
                        <div className="price-header">
                          <h5>BUSINESS</h5>
                          <h3>
                            €495.00 monthly <br />
                          </h3>
                          <span className="d-block"> Plus €0,01/month/ID</span>
                          <span className="d-block">*Setup fee €9.995</span>
                        </div>

                        <div className="price-body">
                          <div className="features justify-content-start">
                            <p><b>Same as BASIC</b></p>
                            {/* <p>&nbsp;</p> */}
                            {/* <p className="w-100 text-start">
                             
                            </p>
                            <p className="feature-label w-100 text-start">
                            </p> */}
                          </div>

                          <div className="features">
                            <ul>
                              <li className="mt-3 font_bold"> ON-LOCATION ID - Agent App *</li>
                              <li className="font_bold">eSIGNATURE - eID *</li>
                              <li>1-100 Agents</li>
                              <li>1-10 Sites</li>
                              <li>1-100 Events annually</li>
                              <li>1-100 Operators</li>
                              <li>1-10.000 IDs</li>
                              <li>1-10.000 Check ID upload / download</li>
                              <li>Email & chat support</li>
                            </ul>
                          </div>
                        </div>
                        <div className="box-price-footer">
                          <button
                            className="price-btn"
                            onClick={() => account("business", "business_monthly")}
                          >
                            14 DAYS FREE TRIAL
                          </button>
                          <span>* Optional implementation of On-location identification (Agent app) or eSignature / eID is charged with an one time setup fee.
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal isOpen={modal} toggle={toggle} className="quote-modal">
        <ModalHeader toggle={toggle}>Get Quote</ModalHeader>
        <ModalBody>
          <div className="loader-box hide">
            <div className="triple-spinner"></div>
          </div>
          <div className="form-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="quote-input">
                <input
                  type="text"
                  placeholder="Name of Person*"
                  {...register("person_name", {
                    required: "This is required.",
                    maxLength: 80,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="person_name"
                  render={({ message }) => (
                    <p className="error-msg">{message}</p>
                  )}
                />
              </div>
              <div className="quote-input">
                <input
                  type="text"
                  placeholder="Company*"
                  {...register("company_name", {
                    required: "This is required.",
                    maxLength: 100,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="company_name"
                  render={({ message }) => (
                    <p className="error-msg">{message}</p>
                  )}
                />
              </div>
              <div className="quote-input">
                <input
                  type="email"
                  placeholder="Email*"
                  {...register("email", {
                    required: "This is required.",
                    pattern: /^\S+@\S+$/i,
                    message: "error message",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  render={({ message }) => (
                    <p className="error-msg">{message}</p>
                  )}
                />
              </div>
              <div className="quote-input">
                <input
                  type="number"
                  placeholder="Mobile*"
                  {...register("mobile_number", {
                    required: "This is required.",
                    maxLength: 12,
                    minLength: 10,
                    valueAsNumber: true,
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="mobile_number"
                  render={({ message }) => (
                    <p className="error-msg">{message}</p>
                  )}
                />
              </div>
              <div className="quote-submit">
                <input type="submit" value="GET QUOTE" />
              </div>
            </form>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={accountModal} className="generate-modal">
        <ModalHeader>
          <img src={logo} alt="1ID" />
          <span className="ac-mid-title">Create Account</span>
          <span className="ac-sub-title">1:PARTNERS</span>
        </ModalHeader>
        <ModalBody>
          <div className="loader-box hide">
            <div className="triple-spinner"></div>
          </div>
          <div className="kyc-account-wrapper">
            <h5>Intro</h5>
            <p className="mb0">You are about to create a corporate account with 1ID ApS. When you sign up and identify yourself you confirm that you are legally entitled to act on the behalf of the company that you represent.</p>
            <p className="mb20">Be aware that you are ONLY allowed to onboard when using 1ID services for corporate account creation and personal identification. If you are NOT a 1ID user, you can download the 1ID App (1ID – My Digital Profile) from the Apple App Store or Google Play Store. </p>
            <h5>FREE Trial or Starter Plan – No Credit Card Required</h5>
            <p className="mb20">When you create an account with 1ID no credit card is required as you are set for a FREE trial or the FREE Starter Plan. You can always upgrade to a paid plan from inside the Admin Panel.</p>
            <h5>Company Profile</h5>
            <p>Insert company name, TIN/VAT (Tax Identification Number), country and address plus your business mobile no. and business email in the below fields.</p>
            <div className="kyc-forn-wrapper">
              <form onSubmit={generateQR} noValidate>
                <div className="qot-row">
                  <div className="qot-col1">
                    <div className="quote-input">
                      <label className="textcenter">Company Name</label>
                      <input
                        type="text"
                        name="company_name"
                        className="textcenter"
                        value={formValues.company_name}
                        onChange={handleChange}
                        readOnly={readInput ? true : false}
                      />
                      {formErrors.company_name && (
                        <span className="error">{formErrors.company_name}</span>
                      )}
                    </div>
                  </div>
                  <div className="qot-col3">
                    <div className="quote-input">
                      <label className="textcenter">
                        TIN/VAT (Tax Identification Number)
                      </label>
                      <input
                        type="number"
                        name="VAT_No"
                        className="textcenter"
                        value={formValues.VAT_No}
                        onChange={handleChange}
                        readOnly={readInput ? true : false}
                      />
                      {formErrors.VAT_No && (
                        <span className="error">{formErrors.VAT_No}</span>
                      )}
                    </div>
                  </div>
                  <div className="qot-col2">
                    <div className="quote-input quote-select">
                      <label className="textcenter">Country</label>
                      <input
                        type="hidden"
                        name="country"
                        id="country"
                        value={formValues.country}
                        onChange={handleChange}
                        readOnly={readInput ? true : false}
                      />
                      <ReactFlagsSelect
                        selected={selected}
                        onSelect={(code) => countryClose(code)}
                        searchable
                      />
                      {formErrors.country && (
                        <span className="error">{formErrors.country}</span>
                      )}
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="qot-row">
                  <div className="qot-col4">
                    <div className="quote-input">
                      <label className="textcenter">Street</label>
                      <input
                        type="text"
                        name="bill_street"
                        className="textcenter"
                        value={formValues.bill_street}
                        onChange={handleChange}
                        readOnly={readInput ? true : false}
                      />
                      {formErrors.bill_street && (
                        <span className="error">{formErrors.bill_street}</span>
                      )}
                    </div>
                  </div>
                  <div className="qot-col4">
                    <div className="quote-input">
                      <label className="textcenter">City</label>
                      <input
                        type="text"
                        name="bill_city"
                        className="textcenter"
                        value={formValues.bill_city}
                        onChange={handleChange}
                        readOnly={readInput ? true : false}
                      />
                      {formErrors.bill_city && (
                        <span className="error">{formErrors.bill_city}</span>
                      )}
                    </div>
                  </div>
                  <div className="qot-col4">
                    <div className="quote-input">
                      <label className="textcenter">State / Region</label>
                      <input
                        type="text"
                        name="bill_state"
                        className="textcenter"
                        value={formValues.bill_state}
                        onChange={handleChange}
                        readOnly={readInput ? true : false}
                      />
                      {formErrors.bill_state && (
                        <span className="error">{formErrors.bill_state}</span>
                      )}
                    </div>
                  </div>
                  <div className="qot-col4">
                    <div className="quote-input">
                      <label className="textcenter">Postal Code / Zip</label>
                      <input
                        type="text"
                        name="bill_zip"
                        className="textcenter"
                        value={formValues.bill_zip}
                        onChange={handleChange}
                        readOnly={readInput ? true : false}
                      />
                      {formErrors.bill_zip && (
                        <span className="error">{formErrors.bill_zip}</span>
                      )}
                    </div>
                  </div>
                </div>
                {/*  */}
                {/*  */}
                <div className="qot-row">
                  <div className="qot-col5 mr2per">
                    <div className="quote-input">
                      <label className="textcenter">Business Email</label>
                      <input
                        type="text"
                        name="company_email"
                        className="textcenter"
                        value={formValues.company_email}
                        onChange={handleChange}
                        readOnly={readInput ? true : false}
                      />
                      {formErrors.company_email && (
                        <span className="error">{formErrors.company_email}</span>
                      )}
                    </div>
                  </div>
                  <div className="qot-col5">
                    <div className="quote-input">
                      <label className="textcenter">
                        Business Mobile
                      </label>
                      <input
                        type="number"
                        name="company_phone"
                        className="textcenter"
                        value={formValues.company_phone}
                        onChange={handleChange}
                        readOnly={readInput ? true : false}
                      />
                      {formErrors.company_phone && (
                        <span className="error">{formErrors.company_phone}</span>
                      )}
                    </div>
                  </div>
                  <div className="qot-col2"></div>
                </div>
                {/*  */}

                {qrtrue == false ? (
                  <div className="quote-submit">
                    <button
                      type="button"
                      className="generate-close"
                      onClick={accountClose}
                    >
                      Cancel
                    </button>
                    <input type="submit" value="NEXT" />
                  </div>
                ) : null}
              </form>
            </div>
          </div>

          {isCompany
            ? ""
            : qrtrue && (
              <div className="generate-div">
                <h5>Terms of Service & Privacy Policy</h5>
                <p className="mb10">Read and approve 1ID legal policies by marking the individual boxes below.</p>
                <h5>Data Share Preset</h5>
                <p className="mb0">
                  Currently the personal data you are automatically sharing when creating a corporate account is preset to: <br />
                  <ul><li>Name, Email, Mobile no, 1ID no. </li></ul>
                </p>
                <p className="mb0">Except from your business mobile no. and business email no other personal data will be collected by 1ID on behalf of your company. All of your personal data is stored in Europe.</p>
                <p className="mb10">Approve Data Share Preset by marking the box below.</p>
                {/*  */}
                <div className="generate-footer mb20">
                  <button type="button" onClick={accountClose}>
                    Cancel
                  </button>
                  <button type="button" className="check-policy">
                    <input
                      type="checkbox"
                      id="terms_check"
                      checked={termsTrue}
                    />
                    <label htmlFor="terms_check" onClick={() => termsCheck()}>
                      <a href="javascript:void(0)">1ID Terms of Service</a>
                    </label>
                  </button>
                  <button type="button" className="check-policy">
                    <input
                      type="checkbox"
                      id="policy_check"
                      checked={privacyTrue}
                    />
                    <label
                      htmlFor="policy_check"
                      onClick={() => privacyCheck()}
                    >
                      <a href="javascript:void(0)">1ID Privacy Policy</a>
                    </label>
                  </button>
                  <button type="button" className="check-policy">
                    <input
                      type="checkbox"
                      id="terms_check"
                      checked={sharePresent}
                    />
                    <label
                      htmlFor="terms_check"
                      onClick={() => dataShareCheck()}
                    >
                      <a href="javascript:void(0)">Data Share Preset</a>
                    </label>
                  </button>
                </div>
                {/*  */}
                <h5>Identify & Create Account</h5>
                <p>Identify yourself and create an account by reading the QR code using the scan function from the bottom navigation of the 1ID App.</p>
                <div className={privacyTerms ? "qr-div" : "qr-div disabled"}>
                  <img src={qrmobile} alt="" />
                  {/* <img src={`https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=KYCSingUp;${cname};${vatno};${device_id};${plantype};${selected}&choe=UTF-8`} alt="" /> */}
                  <QRCode
                    size="200"
                    value={`KYCSingUp;${cname};${vatno};${device_id};${plantype};${selected};${formValues.bill_street};${formValues.bill_city};${formValues.bill_state};${formValues.bill_zip};${formValues.company_email};${formValues.company_phone}`}
                  />
                </div>
              </div>
            )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={messageWarning}
        toggle={messagewarn}
        className="formmsg-modal"
      >
        <ModalBody>
          <div className="form-message-body">
            <img className="warn-img" src={warning} alt="" />
            <h4>Error</h4>
            <p>
              You must read and approve the 1ID Terms of Use & Privacy Policy by
              ticking both boxes.
            </p>
          </div>
          <div className="account-footer">
            <button type="button" className="btn-darken" onClick={messagewarn}>
              OK
            </button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={messageSuccess} className="formmsg-modal">
        <ModalBody>
          <button
            type="button"
            className="back-arrow"
            onClick={messagesucClose}
          >
            <img className="scs-img" src={backArrow} alt="" />
          </button>
          <div className="form-message-body">
            <img className="scs-img" src={success} alt="" />
            <h4>Success!</h4>
            <p>{respmsg}</p>
          </div>
          <div className="account-footer">
            {/* <button type="button" className="btn-darken" onClick={messagesucClose}>
              OK
            </button> */}
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={identifyModal} className="generate-modal identify-modal">
        <ModalHeader>
          <img src={logo} alt="1ID" />
          <span className="ac-mid-title">Create Account</span>
          <span className="ac-sub-title">1:PARTNERS</span>
        </ModalHeader>
        <ModalBody>
          <div className="loader-box hide">
            <div className="triple-spinner"></div>
          </div>
          <div className="kyc-account-wrapper">
            <h5>Personal Data Share</h5>
            <p>
              Users can share their data with your company from within the 1ID
              App based on individual permissions granted.
            </p>
            <p>
              The sharing of data is fixed based upon agreement between 1ID and
              your company and is default set as displayed below.
            </p>
            <p>
              You can always change the setting of Personal Data Share from the
              1:PARTNERS Admin Panel Subscription Section.
            </p>
            <p>
              Do you accept for users to share their personal data according to
              default settings below?
            </p>
            <div className="identify-img">
              <img src={final_mobile} alt="" />
            </div>
            <div className="generate-footer">
              <button type="button" onClick={identifyClose}>
                Back
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <div className="business-plan service-box">
        <div className="container">
          <div className="plan-row border-radius-top">
            <div className="price-upper-box">
              <div className="row">
                {/* <div className="col-4">
                <div className="bus-item">
                  <div className="bus-image">
                    <img
                      src={identification2}
                      alt="Troublesome Identification"
                    />
                  </div>
                  <h4>Auto Identification - Remote & On Location</h4>
                  <p>
                    Know your customers and implement automated identification
                    of all your users. Stay compliant with GDPR and
                    international KYC & AML legislation.
                  </p>
                </div>
              </div> */}
                <div className="col-6">
                  <div className="bus-item">
                    <div className="bus-image">
                      <img src={regulations} alt="Tight Legal Regulations" />
                    </div>
                    <h4>Onboarding Authentication</h4>
                    <p>
                      Onboard new customers fast and secure with two-factor
                      authentication. Implement automated identification and
                      profiling of your users during Sign Up and Log in.
                    </p>
                  </div>
                </div>
                <div className="col-6">
                  <div className="bus-item">
                    <div className="bus-image">
                      <img src={security} alt="Security Concerns" />
                    </div>
                    <h4>Certified eID & eSignature</h4>
                    <p>
                      Add certified eSignature to your digital document signing
                      platform. Combine personal identification and electronic
                      signature in one solution delivered at highest security
                      level according to eIDAS.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="plan-row-wrapper">
            <div className="plan-row border-radius-bottom padding-top0">
              {" "}
              {/*plan-row-box */}
              <div className="plan-content">
                <h4>Enterprise Plan Quotation</h4>
                <p>
                  Enquire now with us and get your customized plan according to
                  your business needs. <br />
                  Click on GET QUOTE button and fill-in your details. We will
                  get back to you shortly.
                </p>
              </div>
              <div className="plan-btn">
                <Button color="danger" onClick={toggle}>
                  GET QUOTE
                </Button>
                <Modal isOpen={modal} toggle={toggle} className="quote-modal">
                  <ModalHeader toggle={toggle}>Get Quote</ModalHeader>
                  <ModalBody>
                    <div className="loader-box hide">
                      <div className="triple-spinner"></div>
                    </div>
                    <div className="form-wrapper">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="quote-input">
                          <input
                            type="text"
                            placeholder="Name of Person*"
                            {...register("person_name", {
                              required: "This is required.",
                              maxLength: 80,
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="person_name"
                            render={({ message }) => (
                              <p className="error-msg">{message}</p>
                            )}
                          />
                        </div>
                        <div className="quote-input">
                          <input
                            type="text"
                            placeholder="Company*"
                            {...register("company_name", {
                              required: "This is required.",
                              maxLength: 100,
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="company_name"
                            render={({ message }) => (
                              <p className="error-msg">{message}</p>
                            )}
                          />
                        </div>
                        <div className="quote-input">
                          <input
                            type="email"
                            placeholder="Email*"
                            {...register("email", {
                              required: "This is required.",
                              pattern: /^\S+@\S+$/i,
                              message: "error message",
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="email"
                            render={({ message }) => (
                              <p className="error-msg">{message}</p>
                            )}
                          />
                        </div>
                        <div className="quote-input">
                          <input
                            type="number"
                            placeholder="Mobile*"
                            {...register("mobile_number", {
                              required: "This is required.",
                              maxLength: 12,
                              minLength: 10,
                              valueAsNumber: true,
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="mobile_number"
                            render={({ message }) => (
                              <p className="error-msg">{message}</p>
                            )}
                          />
                        </div>
                        <div className="quote-submit">
                          <input type="submit" value="GET QUOTE" />
                        </div>
                      </form>
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="business-problem">
        <div className="container">
          <div className="col-12">
            <div className="section-title">
              <span>PROBLEMS</span>
              <h2>
                <strong>Problems </strong> that exisits.
              </h2>
            </div>
          </div>
        </div>
        <div className="problem-area">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img
                      src={identification}
                      alt="Troublesome Identification"
                    />
                  </div>
                  <h4>Troublesome Identification</h4>
                  <p>
                    Many businesses are requested by public authorities to
                    identify their customers but lack an efficient digital
                    procedure whereby customers with no hazzle can identify
                    themselves.
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img src={regulations} alt="Tight Legal Regulations" />
                  </div>
                  <h4>Tight Legal Regulations</h4>
                  <p>
                    Other businesses (car rental, airlines etc.) are due to the
                    nature of their services forced to collect IDs from
                    customers but are struggling to find a way meeting GDPR and
                    ePrivacy regulations.
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img src={security} alt="Security Concerns" />
                  </div>
                  <h4>Security Concerns</h4>
                  <p>
                    More businesses (event organizers) for security reasons have
                    a huge interest in knowing the identity of individuals
                    buying their services but lacks technology solutions for
                    integration with their offerings.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="business-opportunity">
        <div className="container">
          <div className="col-12">
            <div className="section-title">
              <span>Opportunities</span>
              <h2>
                <strong>Opportunities </strong> that follows.
              </h2>
            </div>
          </div>
        </div>
        <div className="problem-area">
          <div className="container">
            <div className="row">
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img src={global} alt="Global Identification" />
                  </div>
                  <h4>Global Identification</h4>
                  <p>
                    Almost all EU countries has implemented digital technology
                    whereby citizens can identify themselves when requested
                    online but none of those solutions covers across EU borders.
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img src={user} alt="Wanting User Experience" />
                  </div>
                  <h4>Wanting User Experience</h4>
                  <p>
                    Using existing digital identification technology (i.e. NemID
                    in Denmark) businesses must not only adapt to individual
                    solutions offered by each EU country but also pay whenever a
                    customer is identified.
                  </p>
                </div>
              </div>
              <div className="col-4">
                <div className="problem-box">
                  <div className="problem-image">
                    <img src={data} alt="Required Data Syncing" />
                  </div>
                  <h4>Required Data Syncing</h4>
                  <p>
                    GDPR and ePrivacy compliance documentation of the users who
                    has shared personal data (and the details of same) and who
                    has reverted sharing is lacking with many businesses.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="business-value">
        <div className="container">
          <div className="col-12">
            <div className="section-title">
              <h2>
                Value <strong>Proposition</strong>
              </h2>
            </div>
          </div>
          <div className="value-area">
            <div className="value-box">
              <ul>
                <li>
                  1:ID guarantees automated secure digital identification of
                  your customers in the most lean and cost effective manner ever
                  invented.
                </li>
                <li>
                  Integrate with the 1:ID API and ask permission to collect data
                  from any private individual who has signed up with 1:ID.
                </li>
                <li>
                  1:ID allows you to speed up the onboarding process of new
                  prospects thereby improving signups numbers and profitability.
                </li>
                <li>
                  1:ID digital profiles are verified by NFC reading of ePassport
                  and OCR scan of EU drivers license. You are no longer required
                  to obtain copies of individual hard copy IDs while all
                  information is already digitally provided and logged by the
                  individuals using the 1:ID app.
                </li>
                <li>
                  When creating a business account your company or organization
                  is automatically listed in the 1:ID app. From there individual
                  users are free to grant permission to the use of their IDs and
                  related data for selected companies and organizations.
                </li>
                <li>
                  1:ID can be used for fast, secure digital identification of
                  larger crowds at big events thereby improving security for
                  spectators, performers and other participants.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="business-register">

        <div className="container">
          <div className="price-wrapper">
            <div className="row">
              <div className="price-row">
                <div className="col-12 price-title partner-title">
                  <div className="bus-image">
                    <img
                      src={ic_partner_account}
                      alt="Troublesome Identification"
                    />
                  </div>
                  <h4>Create Partner Account</h4>
                  <div className="price-footer partner-ac-footer">
                    <button
                      className="signup-btn"
                      onClick={() => account("starter", "starter_free")}
                    >
                      Free Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



      <Footer />
    </div>
  );
};

export default Business;
